// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-serenity-src-pages-404-js": () => import("./../../../node_modules/gatsby-theme-serenity/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-serenity-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-serenity-src-templates-article-archive-js": () => import("./../../../node_modules/gatsby-theme-serenity/src/templates/article-archive.js" /* webpackChunkName: "component---node-modules-gatsby-theme-serenity-src-templates-article-archive-js" */),
  "component---node-modules-gatsby-theme-serenity-src-templates-article-detail-js": () => import("./../../../node_modules/gatsby-theme-serenity/src/templates/article-detail.js" /* webpackChunkName: "component---node-modules-gatsby-theme-serenity-src-templates-article-detail-js" */),
  "component---node-modules-gatsby-theme-serenity-src-templates-page-detail-js": () => import("./../../../node_modules/gatsby-theme-serenity/src/templates/page-detail.js" /* webpackChunkName: "component---node-modules-gatsby-theme-serenity-src-templates-page-detail-js" */)
}

